
.zitat-bg {
	background-color: rgba(255, 255, 255, 0.5);
	margin: 1rem;
	padding: 2rem;
}

.jt-bg {
	background-position: center bottom;
	background-size: cover;
}

.unterzeile {
	color: #4a4074;
	font-size: 1.1vw;
	font-weight: 700;
}