.footer {
    width: 100%;
    bottom: 0;
    background-color: #4a4074;
    color: #fff;
    padding-top: 2rem;
}

.footer_brand {
    float: left;
    font-size: 13px;
    a {
        color: #fff !important;
        &:hover {
            color: #fff;
            text-decoration: underline;
        }
    }
}

.footer_links {
    float: right;
    font-size: 13px;
}

.footer_links a:link {
    color: #fff;
    text-decoration: none;
}

.footer_links a:visited {
    color: #fff;
    text-decoration: none;
}
.footer_links a:hover {
    color: #fff;
    text-decoration: underline;
}
.footer_links a:active {
    color: #fff;
    text-decoration: underline;
}
.kontakt-container {
    padding-top: 4rem;

    margin-top: 2rem;
}
.kontakt-text-container {
    padding-top: 3rem;
    margin-bottom: 2rem;
}
.kontakt-hl {
    font-weight: 900;
    padding-bottom: 1rem;
    font-size: 30px;
}
.contact-form {
    margin-top: 3rem;
    width: 100%;

    #Form_HelloForm {
        width: 100%;
    }

    #Form_HelloForm_Name_Holder,
    #Form_HelloForm_Email_Holder {
        width: 50%;
        color: gray;
        float: left;
        padding-bottom: 2rem;
    }

    #Form_HelloForm_Name {
        border: none;
        border-bottom: 0.8px solid gray;
        width: 95%;
    }

    #Form_HelloForm_Email {
        border: none;
        border-bottom: 0.8px solid gray;
        width: 95%;
    }
    #Form_HelloForm_Anfrage_Holder {
        width: 100%;
        color: gray;
    }
    #Form_HelloForm_Anfrage {
        border: none;
        border-bottom: 0.8px solid gray;
        height: 3rem;
        width: 100%;
    }
    .clear {
        margin-bottom: 1rem;
    }
    #Form_HelloForm_action_doSayHello {
        background-color: #4a4074;
        color: #fff;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 30px;
        padding-right: 30px;
        text-decoration: none;
        border-radius: 20px;
        display: inline-block;
        border: none;
        margin-top: 1rem;
    }
    .btn-toolbar {
        display: block;
        margin-bottom: 1rem;
    }
}

.kontakt-map {
    margin-bottom: 4rem;
    float: right;
    width: 100%;
    .embed-responsive-item {
        width: 100%;
        height: 700px;
        @media (max-width: 992px) {
            height: 450px;
        }
    }
}
