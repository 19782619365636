@import "bootstrap";
@import "header";
@import "footer";
@import "teaser";
@import "zitatblock";
@import "twocolumns";
@import "personblock";
@import "fonts";

body {
    padding-top: 3.5rem;
    text-align: center !important;
    font-family: "Playfair Display";
}

main {
    padding-top: 3rem;
}

.jumbotron {
    border-radius: 0;
}

h1 {
    text-transform: uppercase;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.8px;
}

h2 {
    padding-bottom: 1.5rem;
    font-weight: bold;
}

h3 {
    padding-bottom: 1rem;
    font-weight: 700;
    font-size: 42px;
}

@media (max-width: 768px) {
    h2 {
        font-size: 1.5rem;
    }
    h3 {
        font-size: 37px;
    }
}

hr {
    color: #4a4074;
    font-weight: 700;
    border-width: 2px;
    margin-right: 20%;
    margin-left: 20%;
}

a {
    color: #4a4074;
}

a:hover {
    text-decoration: underline;
    color: #4a4074;
}

.white-text-box {
    margin-top: -440px;
    padding-top: 7%;
    padding-right: 10%;
    padding-left: 10%;
    padding-bottom: 1rem;
}

li {
    margin-bottom: 2rem;
}

.bg-page-bottom {
    background-image: url(/img/bg-bottom-psychothreapie-hofheim.jpg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center bottom;

    @media (max-width: 768px) {
        background-image: url(/img/bg-bottom-psychothreapie-hofheimMobil.jpg);
    }
}

#CybotCookiebotDialogBodyButtonAccept {
    line-height: 3 !important;
    font-size: 1.4em !important;
    border: none !important;
    background-color: #4a4074 !important;
    color: white !important;
}
#CybotCookiebotDialogBodyButtonDecline {
    color: #000 !important;
    background-color: #fff !important;
    text-decoration: underline !important;
    width: auto !important;
    border: none !important;
}

section.kontakt {
    position: relative;
}

.footer-kontakt {
    width: 100%;
    min-height: 5rem;
    background-color: #4a4074;
    color: #fff;
    padding-top: 2rem;
    position: absolute;
    bottom: -500px;
}
