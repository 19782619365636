.headline-lebenslauf {
	color: #000;
	font-size: 20px;
	font-weight: 900;
	padding-bottom: 1.5rem;
	padding-top: 1rem;
}


.jahr-ll {
	font-size: 20px;
	font-weight: 700;
	text-align: right;
}

.job-ll {
	text-align: left; 
}

.person-block {
	padding-right: 10%;
	padding-left: 10%;
}

