/* playfair-display-regular - latin */
@font-face {
    font-family: "Playfair Display";
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/playfair-display-v30-latin-regular.eot"); /* IE9 Compat Modes */
    src: local(""),
        url("../fonts/playfair-display-v30-latin-regular.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("../fonts/playfair-display-v30-latin-regular.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("../fonts/playfair-display-v30-latin-regular.woff")
            format("woff"),
        /* Modern Browsers */
            url("../fonts/playfair-display-v30-latin-regular.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("../fonts/playfair-display-v30-latin-regular.svg#PlayfairDisplay")
            format("svg"); /* Legacy iOS */
}

/* playfair-display-700 - latin */
@font-face {
    font-family: "Playfair Display";
    font-style: normal;
    font-weight: 700;
    src: url("../fonts/playfair-display-v30-latin-700.eot"); /* IE9 Compat Modes */
    src: local(""),
        url("../fonts/playfair-display-v30-latin-700.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("../fonts/playfair-display-v30-latin-700.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("../fonts/playfair-display-v30-latin-700.woff") format("woff"),
        /* Modern Browsers */ url("../fonts/playfair-display-v30-latin-700.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("../fonts/playfair-display-v30-latin-700.svg#PlayfairDisplay")
            format("svg"); /* Legacy iOS */
}

/* playfair-display-900 - latin */
@font-face {
    font-family: "Playfair Display";
    font-style: normal;
    font-weight: 900;
    src: url("../fonts/playfair-display-v30-latin-900.eot"); /* IE9 Compat Modes */
    src: local(""),
        url("../fonts/playfair-display-v30-latin-900.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("../fonts/playfair-display-v30-latin-900.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("../fonts/playfair-display-v30-latin-900.woff") format("woff"),
        /* Modern Browsers */ url("../fonts/playfair-display-v30-latin-900.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("../fonts/playfair-display-v30-latin-900.svg#PlayfairDisplay")
            format("svg"); /* Legacy iOS */
}
