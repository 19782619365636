.two-column-box {
	padding-top: 12px;
	padding-right: 10%;
	padding-left: 10%;
	padding-bottom: 2rem;
	columns: 2;
	column-gap: 3rem;
	text-align: left;
	color: #000;
}

@media (max-width: 1000px) {
	.two-column-box {
		columns: 1;
		padding-left: 5%;
		padding-right: 5%;
	}
}



.two-column-hl {
	text-align: center;
	font-weight: 700;
	font-size: 1.8rem;
	padding-bottom: 1.3rem;

}
