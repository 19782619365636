.praxisteasercontent{
	margin-top: -70px;
	padding-right: 10%;
	padding-left: 10%;
}

.bg-header {
	background-image: url(/img/header-bg-psychothreapie-hofheim.jpg);
	min-height: 500px;
	padding-top: 150px;
	color: #fff;

	h2 {
		padding-bottom: 1rem;
		font-weight: 700;
		font-size: 42px;
		@media (max-width: 768px) {
            font-size: 37px;
        }
	}

	h3 {
		text-transform: uppercase;
		font-weight: 400;
		font-size: 16px;
		letter-spacing: 0.8px;
		@media (max-width: 768px) {
            font-size: 16px;
        }
	}
}

.bg-bild-koepfe {
	background-image: url(/img/bg-faces-psychothreapie-hofheim.jpg);
	background-size: cover;
	padding-top: 5%;
	padding-bottom: 5%;
	@media (max-width: 768px) {
		background-image: url(/img/bg-faces-psychothreapie-hofheimMobil.jpg);
	}
	@media (max-width: 480px) {
		background-image: url(/img/bg-faces-psychothreapie-hofheimMobil2.jpg);
	}
}

.bg-purple-transparent {
	background-color: rgba(74,64,116,0.75); 
	color: #fff; 
	padding: 7%;
}

.personteasercontent {
	padding-right: 10%;
	padding-left: 10%;
	padding-bottom: 2rem;
}

.bg-purple {
	background-color: rgba(74,64,116,1);
	padding: 12%;
	color: #fff;
}

.bedingungenteasercontent {
	padding-right: 10%;
	padding-left: 10%;
	margin-top: 4rem;
	padding-bottom: 1rem;
}

.bg-bottom {
	background-image: url(/img/bg-bottom-psychothreapie-hofheim.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	bottom: 0px;
	margin-top: -50px;
	@media (max-width: 768px) {
		background-image: url(/img/bg-bottom-psychothreapie-hofheimMobil.jpg);
	}
}
