.navbar-light .navbar-nav .nav-link {
	color: #000;
}

.navbar-light .navbar-nav .nav-link:hover {
	color: #000;
}

.nav-item {
	padding-left: 8px;
	padding-right: 8px;
}


.nav-link {
	text-transform: uppercase;
	font-size: 13px;
	font-weight: 700;
	letter-spacing: 1.3px;
}

.nav-link:hover {
	color: #000;
	font-weight: 700;
}

.current {
	border-bottom: solid 1.5px black;
}

button:focus {
    outline: none;
}

@media (max-width: 768px) {
	.header-logo {
		width: 170px;
	}
}

.mt-0 {
	text-transform: uppercase;
	font-size: 13px;
	font-weight: 700;
	letter-spacing: 1.3px;
	color: #000;
	font-weight: 900;
}

.navbar {
	@media (max-width: 992px) {
		&.show {
			box-shadow: 0px 0px 10px gray;
		}
	}
}

.navbar-nav li {
	margin-bottom: unset;
}

.logo-link {
	text-decoration: none;
	.mt-0 {
		max-width: 150px;
	}
}

.logo-link:hover {
	text-decoration: none;
}